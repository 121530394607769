<template>
  <div>
    <department-add></department-add>
  </div>
</template>

<script>
import DepartmentAdd from './DepartmentAdd.vue';

export default {
  components: {
    DepartmentAdd,
  },

  data() {
    return {};
  },
};
</script>
